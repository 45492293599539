<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :owner="this"
        :can-create="$isUserAdmin"
      >
        <template #create-action>
          <ExpandableButton
            v-if="$isUserAdmin"
            large
            button-class="wethrive px-5"
            button-style="display: grid;"
            icon="add"
            :to="{
              hash: '#send-invitations'
            }"
            :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
          />
        </template>
        <template #actions-left>
          <ExpandableButton
            v-if="canImport"
            icon=""
            text="Import"
            button-class="mr-3"
            color="info"
            :depressed="true"
            :large="true"
            :flex="true"
            :block="$vuetify.breakpoint.smAndDown"
            :to="{
              hash: '#import'
            }"
            :tooltip-text="`Import ${featureName('users').toLowerCase()}`"
          />
          <ExpandableButton
            v-if="canImport"
            icon=""
            text="Applications"
            tooltip-text="Manage applications and pipelines"
            button-class=""
            color="info"
            :depressed="true"
            :large="true"
            :flex="true"
            :block="$vuetify.breakpoint.smAndDown"
            :to="{
              name: 'settings',
              hash: '#pipelines'
            }"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      mb-2
      shrink
    >
      <Stats
        :stats="stats"
        :options="statOptions"
      />
    </VCol>
    <VCol style="flex: 1 1 auto">
      <VTabs
        lazy
        grow
        slider-color="primary"
        class="d-none"
      >
        <VTab
          v-for="(tab, key) in tabs"
          :to="{
            hash: tab.url
          }"
          :key="key"
        >
          {{ tab.title }}
        </VTab>
      </VTabs>
      <VTabsItems
        touchless
        :value="activeUrlTab"
      >
        <VTabItem>
          <Users :prepared-config="config" />
        </VTabItem>
        <VTabItem>
          <Invitations />
        </VTabItem>
        <VTabItem>
          <Import :prepared-config="config" />
        </VTabItem>
        <VTabItem>
          <Export :prepared-config="config" />
        </VTabItem>
        <VTabItem>
          <Save
            ref="saveUser"
            hide-back-button
            class="px-4"
            @updated="$refs.saveUser.resetForm()"
          />
        </VTabItem>
      </VTabsItems>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import { mapActions } from "vuex";
import Save from "./Save";
import Invitations from "./Invitations";
import Import from "./Import";
import Export from "./Export";
import Users from "./Users";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";

export default {
  name: "UserIndex",
  components: {
    Save,
    Invitations,
    Import,
    Export,
    Users,
    IndexHeader,
  },
  mixins: [SearchMixin, HasStats],
  data () {
    return {
      dialogs: {
        approve: false,
      },
      isApproving: false,
      date: null,
      modelType: "users",
      config: {
        stats: {
          "-": "-",
        },
      },
      statOptions: {
        indicators: {
          pending_approval: function (value, type) {
            return value > 0 && type === "danger" ? true : false;
          },
        },
      },
    };
  },
  computed: {
    stats() {
      if (this.$isUserAdmin) {
        return this.config.stats;
      } else {
        return {
          users: this.config.stats.users,
        };
      }
    },
    tabs() {
      return [
        {
          title: "Users",
          url: "",
          enabled: true,
        },
        {
          title: "Invitations",
          url: "#send-invitations",
          enabled: true,
        },
        {
          title: "Import",
          url: "#import",
          enabled: true,
        },
        {
          title: "Export",
          url: "#export",
          enabled: true,
        },
        {
          title: `Create ${this.featureName(this.modelType.capitalize(), 'singularize')}`,
          url: "#create-user",
          enabled: true,
        },
      ];
    },
    canImport () {
      return this.$isUserAdmin;
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "users/getIndexConfig",
    }),
    async onGetIndexConfig () {
      return await this.doGetIndexConfig();
    },
  },
};
</script>
