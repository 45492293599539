<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <DataViewUI
        :data="data"
        :headers="headers"
        :is-loading="activity.isLoading"
        :filters="allFilters"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>
            <div style="width: max-content">
              <b
                :class="{
                  'success--text': props.item.id === $user.id
                }"
              >{{ props.item.name }}</b>

              <template v-if="props.item.active_submitted_applications_count > 0">
                <Tooltip
                  small
                  as-button
                  icon-button
                  icon="assignment"
                  title="View acitive applications"
                  :action="() => onSetViewDialogModel(props.item)"
                />
              </template>
            </div>
          </td>
          <td>
            <Tooltip
              v-if="props.item.role_is_invalid"
              small
              label
              text-color="white"
              color="error"
              title="Please assign this user a valid role"
              button-text="Unassigned"
              :icon="false"
              :as-button="false"
              :rounded="false"
            />
            <RoleChip
              v-if="!props.item.role_is_invalid"
              :user="props.item"
            />
          </td>
          <td>{{ props.item.email || 0 }}</td>
          <td>{{ props.item.city ? props.item.city.name : '(not set)' }}</td>
          <td>
            <VBtn
              v-if="canEditUser(props.item)"
              v-model="props.item.team_user.is_approved"
              icon
              small
              :color="props.item.team_user.is_approved ? 'primary' : 'disabled'"
              @click.stop="onApprove(props.item)"
            >
              <VIcon>{{ props.item.team_user.is_approved ? 'check_box' : 'check_box_outline_blank' }}</VIcon>
            </VBtn>
          </td>
          <td>{{ $timezone.formatDateTime(props.item.last_login) }}</td>
          <td>{{ $timezone.formatDateTime(props.item.last_active) }}</td>
          <td>
            <VChip
              v-if="props.item.engagement_level == 'not-approved'"
              color="disabled"
              label
            >
              Not Approved
            </VChip>
            <VChip
              v-else-if="props.item.engagement_level == 'engaged'"
              label
              color="success"
              text-color="white"
            >
              Engaged ({{ props.item.last_engaged }} Days)
            </VChip>
            <VChip
              v-else-if="props.item.engagement_level == 'at-risk'"
              label
              color="warning"
              text-color="white"
            >
              At Risk ({{ props.item.last_engaged }} Days)
            </VChip>
            <VChip
              v-else
              label
              color="error"
              text-color="white"
            >
              <template v-if="props.item.last_engaged > 0">
                Disengaged ({{ props.item.last_engaged }} Days)
              </template>
              <template v-else>
                Not Active
              </template>
            </VChip>
          </td>
          <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
          <td class="actions">
            <Menu :disabled="getIsDisabled(props.item)">
              <VListItem
                v-if="canEditUser(props.item)"
                :href="getHrefForItem(`${modelTypeRoute.singularize()}.update`, props.item.id)"
                @click.prevent="onSetFormDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute.singularize()}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="canEditUser(props.item)"
                @click.prevent="deleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Remove from Team
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        :can-delete="false"
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel ? currentModel.name : ''"
        :model-type="modelType"
        @toggle:form:dialog="onToggleFormDialog"
      >
        <Save
          ref="saveForm"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
        />
      </FormDialog>
      <Confirm
        max-width="300"
        ref="confirmDelete"
        :hide-cancel="hideCancel"
        :confirm-text="hideCancel ? 'Ok' : 'Yes'"
      />
      <Dialog
        :is-dialog-open="dialogs.approve"
        title="Approve"
        persistent
        @toggle:dialog="onToggleApproveDialog"
      >
        <VRow
          v-if="currentModel"
          column
        >
          <VCol cols="12">
            <p class="px-6">
              Are you sure you want to approve this user?
            </p>
          </VCol>
          <VCol cols="12">
            <div class="d-flex align-center justify-space-between px-4">
              <VBtn
                class="mr-2"
                text
                @click="onToggleApproveDialog"
              >
                Cancel
              </VBtn>
              <VBtn
                color="success"
                :disabled="isApproving"
                @click="onDoApprove()"
              >
                <template v-if="!currentModel.team_user.is_approved">
                  {{ isApproving ? 'Approving' : 'Approve' }}
                </template>
                <template v-else>
                  {{ isApproving ? 'Dis-Approving' : 'Dis-Approve' }}
                </template>
              </VBtn>
            </div>
          </VCol>
        </VRow>
      </Dialog>
      <Dialog
        max-width="75%"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('User')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          :id="currentModel ? currentModel.id : null"
          ref="viewItem"
          mode="dialog"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import Save from "./Save";
import { mapActions } from "vuex";
import ViewComponent from "./View";
import RoleChip from "@/components/Elements/RoleChip";

export default {
    name: "UserIndex",
    props: {
      preparedConfig: {
          type: Object,
          default: () => ({}),
      },
    },
    components: {
      Save,
      ViewComponent,
      RoleChip,
    },
    mixins: [SearchMixin, HasStats],
    data () {
      return {
        dialogs: {
          approve: false,
        },
        isApproving: false,
        hideCancel: false,
        date: null,
        modelType: "users",
        deleteAlertTitle: "Remove from Team",
        config: {
          stats: {
            "-": "-",
          },
        },
      };
    },
    computed: {
      headers () {
        let headers;
        if (this.$isUserAdmin) {
          headers = [
            {
              text: "Name",
              align: "left",
              sortable: true,
              value: "name",
            },
            {
              text: "Role",
              align: "left",
              sortable: true,
              value: "team_user.role",
            },
            {
              text: "Email",
              align: "left",
              sortable: true,
              value: "email",
            },
            {
              text: "City",
              align: "left",
              sortable: true,
              value: "city.name",
            },
            {
              text: "Approved",
              align: "left",
              sortable: true,
              value: "team_user.is_approved",
            },
            {
              text: "Last Login",
              align: "left",
              sortable: true,
              value: "last_login",
            },
            {
              text: "Last Active",
              align: "left",
              sortable: true,
              value: "last_active",
            },
            {
              text: "Engagement",
              align: "left",
              sortable: true,
              value: "engagementLevel",
            },
            {
              text: "Joined",
              align: "left",
              sortable: true,
              value: "created_at",
            },
          ];
        } else {
          headers = [
            {
              text: "User",
              align: "left",
              sortable: true,
              value: "name",
            },
            {
              text: this.featureName("Students"),
              align: "left",
              sortable: true,
              value: "students_count",
            },
            {
              text: "City",
              align: "left",
              sortable: true,
              value: "city.name",
            },
            {
              text: "AVG Rating",
              align: "left",
              sortable: true,
              value: "rating",
            },
            {
              text: "Last Login",
              align: "left",
              sortable: true,
              value: "last_login",
            },
            {
              text: "Joined",
              align: "left",
              sortable: true,
              value: "created_at",
            },
          ];
        }
        return headers;
      },
      canImport () {
        return this.$isUserAdmin;
      },
    },
    methods: {
      ...mapActions({
        doGetIndexConfig: "users/getIndexConfig",
        doGetUsers: "users/getAll",
        doGetUser: "users/getOne",
        doDeleteMember: "team/deleteMember",
        doApproveUser: "team/approveUser",
      }),
      onGetIndexConfig () {
        return !this.preparedConfig.filters ? this.doGetIndexConfig() : this.preparedConfig;
      },
      onApprove (user) {
        this.dialogs.approve = true;
        this.currentModel = user;
      },
      onDoApprove () {
        this.isApproving = true;
        this.doApproveUser(this.currentModel.id)
          .then((result) => {
            const teamUser = result;
            const index = this.data.data.findIndex(
              (user) => user.id === this.currentModel.id
            );
            this.data.data[index].team_user = teamUser;
            this.dialogs.approve = this.isApproving = false;
            this.$bus.$emit(
              "notificationMessage",
              teamUser.is_approved ? "Approved user!" : "Dis-Approved user!"
            );
            if (this.stats) {
              const increment = teamUser.is_approved ? -1 : 1;
              this.stats.pending_approval =
                this.stats.pending_approval < 1 && increment === -1
                  ? 0
                  : this.stats.pending_approval + increment;
            }
          })
          .catch((error) => {
            this.dialogs.approve = this.isApproving = false;
            this.$bus.$emit("notificationError", "Unable to approve user");
          });
      },
      onToggleApproveDialog () {
        this.dialogs.approve = !this.dialogs.approve;
      },
      onDelete (user) {
        return this.doDeleteMember(user.id).then((result) => {
          if (!user.team_user.is_approved) {
            this.config.stats.pending_approvals--;
          }
          return result;
        });
      },
      deleteItem(user) {
        const teamUser = user.team_user || {};
        if(teamUser.role === "owner") {
          this.hideCancel = true;
          this.deleteAlertMessage = "Please contact support using the help icon.";
          this.onDeleteItem(user, () => {});
        } else {
          this.hideCancel = false;
          this.deleteAlertMessage = "Are you sure you want to remove this user for forever? This action can not be undone.";
          this.onDeleteItem(user, null, null, false).then((result) => {
            if(result) {
              this.$bus.$emit("notificationMessage", "Successfully removed from team!");
            }
          });
        }
      },
      onGetAll (params) {
        return this.doGetUsers(params);
      },
      onGet (id) {
        return this.doGetUser(id);
      },
      canEditUser (user) {
        const teamUser = user.team_user || {};
        return (
          teamUser.role !== "owner" ||
          (teamUser.role === "owner" && this.$user.team_user.role === "owner")
        );
      },
    },
    mounted () {
      this.config = this.preparedConfig;
    }
};
</script>
