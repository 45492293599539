<template>
  <VCard id="user-export-card">
    <VCardText v-if="exportComplete">
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VAlert
        v-else-if="exportComplete"
        type="success"
        :value="true"
      >
        Your request is running in the background. You'll receive a notification once it's done. If you don't see the export please check your Spam folders. If you still don't receive the export in 15 mins, please contact support.
        <VBtn
          large
          color="primary"
          class="my-0"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset(true)"
        >
          Start Over
        </VBtn>
      </VAlert>
    </VCardText>
    <VCardText v-else>
      <VSheet
        rounded
        color="grey lighten-2"
        class="px-4 py-4"
      >
        <VRow
          row
          wrap
          align-center
        >
          <VCol cols="12">
            <VAlert
              type="warning"
              :value="$route.query.program_id > 0"
            >
              You have limited this export to a specific {{ featureName('program').singularize() }}. To remove this <VBtn
                small
                depressed
                color="info"
                @click="onRemoveProgramFilter"
              >
                Click Here
              </VBtn>
            </VAlert>
            <h2 class="title mb-4">
              Configure export options here
            </h2>
            <VRow
              align-center
              slot="append-outer"
            >
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 3 : 1"
                :class="{
                  'mt-5': $vuetify.breakpoint.smAndDown
                }"
                :grow="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 4 : 'auto'"
              >
                <Autocomplete
                  v-model="filter.programs"
                  filled
                  multiple
                  item-text="title"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  hide-details
                  :dense="false"
                  :disabled="activity.isLoading"
                  :label="`(Optional) Select ${featureName('programs')}`"
                  :placeholder="`Select ${featureName('programs')}`"
                  :loading="activity.isOptionsLoading"
                  :items="filterOptions.program"
                  :error="$v.form.programs.$error"
                  @input="$v.form.programs.$touch()"
                  @blur="onSyncFilterToForm"
                />
              </VCol>
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
                :shrink="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 6 : 'auto'"
              >
                <VBtn
                  large
                  text
                  color="dark"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="activity.isFormLoading"
                  @click="onReset"
                >
                  Start Over
                </VBtn>
              </VCol>
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 2 : 3"
                :shrink="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 6 : 'auto'"
              >
                <VBtn
                  large
                  color="info"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="activity.isFormLoading || !isFormReady"
                  @click="onSave"
                >
                  Run Export
                </VBtn>
              </VCol>
            </VRow>
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <Autocomplete
              v-model="filter.roles"
              filled
              multiple
              hide-details
              item-text="title"
              item-value="id"
              append-inner-icon="arrow-down"
              :dense="false"
              :disabled="activity.isLoading"
              :label="`(Optional) Select roles`"
              :placeholder="`Select roles`"
              :loading="activity.isOptionsLoading"
              :items="filterOptions['team_user.role']"
              :error="$v.form.roles.$error"
              @input="$v.form.roles.$touch()"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <VCombobox
              v-model="form.emails"
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              hide-details
              label="(Optional) Send the report to these emails"
              placeholder="Enter emails"
              :disabled="activity.isLoading"
            />
          </VCol>
          <VCol
            :class="{
              'mt-5': $vuetify.breakpoint.smAndDown
            }"
            :grow="$vuetify.breakpoint.mdAndUp"
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
          >
            <Autocomplete
              v-model="filter.city"
              filled
              multiple
              item-text="name"
              item-value="id"
              append-inner-icon="arrow-down"
              hide-details
              :dense="false"
              :disabled="activity.isLoading"
              :label="`(Optional) Select ${featureName('city')}`"
              :placeholder="`Select ${featureName('city')}`"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.city"
              :error="$v.form.city.$error"
              @input="$v.form.city.$touch()"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol>
            <VRadioGroup
              v-model="filter.is_approved"
              :loading="activity.isOptionsLoading"
              :disabled="activity.isLoading"
              @change="onSyncFilterToForm"
            >
              <VRadio
                label="Include any users"
                value="0"
              />
              <VRadio
                label="Include only approved users"
                value="true"
              />
              <VRadio
                label="Include only unapproved users"
                value="false"
              />
            </VRadioGroup>
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <VDialog
              v-model="dialogs.createdBetweenStart"
              width="290px"
              ref="createdBetweenStartDialog"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.created_after"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  clearable
                  hide-details
                  label="(Optional) Select joined after date"
                  prepend-icon="event"
                  :disabled="activity.isLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.created_after"
                scrollable
                clearable
                color="info"
                :disabled="activity.isLoading"
                @input="dialogs.createdBetweenStart = false"
              />
            </VDialog>
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <VDialog
              v-model="dialogs.createdBetweenEnd"
              width="290px"
              ref="createdBetweenEndDialog"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.created_before"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  clearable
                  readonly
                  hide-details
                  label="(Optional) Select joined before date"
                  prepend-icon="event"
                  :disabled="activity.isLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.created_before"
                scrollable
                clearable
                outlined
                color="info"
                :disabled="activity.isLoading"
                @input="dialogs.createdBetweenEnd = false"
              />
            </VDialog>
          </VCol>
        </VRow>
      </VSheet>
      <Users
        disable-filter
        mode="embedded"
        :filter-on-filter-update="canFilterOnUpdate"
        :custom-filter="realFilter"
        @startLoading="emitLoading"
        @doneLoading="emitLoadingDone"
      />
    </VCardText>
    <Confirm ref="confirmReset" />
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import ActivityMixin from "@/mixins/Activity";
import { email } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import HasPagination from "@/mixins/HasPagination";
import Users from "./Users";
import CanFilter from "@/mixins/CanFilter";

export default {
    name: "ExportUsers",
    mixins: [ActivityMixin, FormMixin, HasPagination, CanFilter],
    components: { Users },
    props: {
        preparedConfig: {
            type: Object,
            default: () => ({}),
        },
    },
    data () {
        return {
            enableBackgroundSearch: true,
            dialogs: {
                createdBetweenStart: false,
                createdBetweenEnd: false,
            },
            options: {
                programs: [],
            },
            error: "",
            message: "",
            filter: {
                roles: [],
                city: [],
                is_approved: false,
                programs: [],
                created_before: null,
                created_after: null,
            },
            form: {
                programs: [],
                emails: [],
                roles: [],
                city: []
            },
            defaultForm: {
                s: "",
                roles: [],
                programs: [],
                summarize: true,
                city: [],
                is_approved: 0,
                include_message_dates: false,
                created_before: null,
                created_after: null,
            },
        };
    },
    validations: {
        form: {
            city: {},
            roles: {},
            programs: {},
            $each: {
                emails: {
                    email,
                },
            },
        },
    },
    computed: {
        realFilter () {
            return {
                filter: {
                    s: this.form.s,
                    city: this.form.city,
                    is_approved: this.form.is_approved,
                    roles: this.form.roles,
                    program: this.form.programs,
                    created_before: this.form.created_before,
                    created_after: this.form.created_after,
                },
            };
        },
        exportComplete () {
            return (this.saveResult || {}).isQueued;
        },
        hasErrorsOrMessages () {
            return this.error.length >= 0 || this.message.length > 0;
        },
    },
    watch: {
        "$route.query.program_id": function (value) {
            if (value) {
                this.onReset(true, true);
                this.form.programs = this.filter.programs = [value];
            }
        },
    },
    mounted () {
        this.form.emails = [this.$user.email];
        if (this.$route.query.program_id) {
            this.form.programs = this.filter.programs = [
                parseInt(this.$route.query.program_id),
            ];
        }
    },
    methods: {
        ...mapActions({
            doGetIndexConfig: "users/getIndexConfig",
            doExportUsers: "users/export",
            doGetUsers: "users/getAll",
        }),
        onGetIndexConfig () {
            return !this.preparedConfig.filters ? this.doGetIndexConfig() : this.preparedConfig;
        },
        onSearch (s) {
            return this.saved.data.filter((program) => {
                if (this.filter && this.filter.length) {
                    const titleMatch = program.title.match(this.filter);
                    return (
                        (titleMatch && titleMatch.length > 0)
                    );
                }
                return true;
            });
        },
        onSave () {
            return this.saveFormModel(this.doExportUsers);
        },
        onReset (force, resetSelected) {
            const resetFunc = (result, shouldResetEntities) => {
                if (result === true) {
                    this.error = this.message = "";
                    this.errors = this.messages = [];
                    this.saveResult = null;
                    this.form = { ...this.defaultForm };
                }
            };

            if (force === true) {
                resetFunc(true, resetSelected);
            } else {
                this.$refs["confirmReset"]
                    .open("Reset", "Are you sure?", {
                        color: "orange",
                    })
                    .then((result) => {
                        resetFunc(result, true);
                    });
            }
        },
        onGetFormConfig () {
            return this.doGetIndexConfig().then((result) => (this.config = result));
        },
        onGetUsers () {
            this.$emit("doneLoading");
            this.$emit("startOptionsLoading");
            return this.doGetUsers({
                _withCustom: true,
                filter: {
                    id: this.$route.query.program_id,
                },
            })
                .then((result) => {
                    this.options.programs = result.data;
                    if (this.$route.query.program_id) {
                        this.form.programs = [
                            parseInt(this.$route.query.program_id),
                        ];
                    }
                })
                .catch(() => {
                    this.$emit("doneOptionsLoading");
                })
                .then(() => {
                    this.$emit("doneOptionsLoading");
                });
        },
        getUniqueKeyValue (index) {
            let item = index instanceof Object ? index : this.prepared.data[index];
            if (item) {
                const metadata = item.metadata.find(
                    (item) =>
                        item.name ==
                        (this.uniqueKey instanceof Object
                            ? this.uniqueKey.id
                            : this.uniqueKey)
                );
                if (metadata) {
                    return metadata.value;
                }
            }
            return "None";
        },
        onPaginatePreparedLocal (pagination) {
            this.preparedPaginated = {
                ...this.preparedPaginated,
                ...this.getLocalDataPaginated(pagination, "prepared"),
            };
        },
        onPaginateSavedLocal (pagination) {
            this.savedPaginated = {
                ...this.savedPaginated,
                ...this.getLocalDataPaginated(pagination, "saved"),
            };
        },
        onUpdatePaginationPreparedLocal (pagination) {
            this.onPaginateLocal(pagination, "preparedPaginated");
            this.onPaginatePreparedLocal(pagination);
        },
        onUpdatePaginationSavedLocal (pagination) {
            this.onPaginateLocal(pagination, "savedPaginated");
            this.onPaginateSavedLocal(pagination);
        },
        onRemoveProgramFilter () {
            delete this.$route.query.program_id;
            this.onGetExportConfig();
        },
    },
};
</script>