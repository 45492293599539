var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":""}},[_c('VCol',{staticStyle:{"flex":"0 0 64px"}},[_c('IndexHeader',{attrs:{"mode":"standalone","owner":this,"can-create":_vm.$isUserAdmin},scopedSlots:_vm._u([{key:"create-action",fn:function(){return [(_vm.$isUserAdmin)?_c('ExpandableButton',{attrs:{"large":"","button-class":"wethrive px-5","button-style":"display: grid;","icon":"add","to":{
            hash: '#send-invitations'
          },"tooltip-text":`Add ${_vm.featureName(_vm.modelType.capitalize(), 'singularize')}`}}):_vm._e()]},proxy:true},{key:"actions-left",fn:function(){return [(_vm.canImport)?_c('ExpandableButton',{attrs:{"icon":"","text":"Import","button-class":"mr-3","color":"info","depressed":true,"large":true,"flex":true,"block":_vm.$vuetify.breakpoint.smAndDown,"to":{
            hash: '#import'
          },"tooltip-text":`Import ${_vm.featureName('users').toLowerCase()}`}}):_vm._e(),(_vm.canImport)?_c('ExpandableButton',{attrs:{"icon":"","text":"Applications","tooltip-text":"Manage applications and pipelines","button-class":"","color":"info","depressed":true,"large":true,"flex":true,"block":_vm.$vuetify.breakpoint.smAndDown,"to":{
            name: 'settings',
            hash: '#pipelines'
          }}}):_vm._e()]},proxy:true}])})],1),_c('VCol',{attrs:{"mb-2":"","shrink":""}},[_c('Stats',{attrs:{"stats":_vm.stats,"options":_vm.statOptions}})],1),_c('VCol',{staticStyle:{"flex":"1 1 auto"}},[_c('VTabs',{staticClass:"d-none",attrs:{"lazy":"","grow":"","slider-color":"primary"}},_vm._l((_vm.tabs),function(tab,key){return _c('VTab',{key:key,attrs:{"to":{
          hash: tab.url
        }}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),1),_c('VTabsItems',{attrs:{"touchless":"","value":_vm.activeUrlTab}},[_c('VTabItem',[_c('Users',{attrs:{"prepared-config":_vm.config}})],1),_c('VTabItem',[_c('Invitations')],1),_c('VTabItem',[_c('Import',{attrs:{"prepared-config":_vm.config}})],1),_c('VTabItem',[_c('Export',{attrs:{"prepared-config":_vm.config}})],1),_c('VTabItem',[_c('Save',{ref:"saveUser",staticClass:"px-4",attrs:{"hide-back-button":""},on:{"updated":function($event){return _vm.$refs.saveUser.resetForm()}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }